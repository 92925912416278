// declare variables
const plans = document.getElementById("plans");
const urlParams = new URLSearchParams(window.location.search);
const appName = urlParams.get("app_name");
const serial_device = urlParams.get("serial_device") || null;
const email = urlParams.get("email") || null;
const firstName = urlParams.get("first_name") || null;
const phone = urlParams.get("phone_number") || null;
const country = urlParams.get("country") || null;
const vatId = urlParams.get("vat_id");

const params = [];

let data;

let url;




// conditions

if (firstName) params.push(`first_name=${encodeURIComponent(firstName)}`);
if (email) params.push(`email=${encodeURIComponent(email)}`);
if (country) params.push(`country=${encodeURIComponent(country)}`);
if (phone) params.push(`phone_number=${encodeURIComponent(phone)}`);
if (vatId) params.push(`vat_id=${encodeURIComponent(vatId)}`);

const queryString = params.length ? `?${params.join("&")}` : "";

// add data

// functions

// Get Data

async function fetchCatalogData() {
  const username = "admin";
  const password = "password";
  const authToken = btoa(`${username}:${password}`);

  try {
    const response = await fetch(
      "https://appyinvoice.com:8443/1.0/kb/catalog",
      {
        method: "GET",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Basic ${authToken}`,
          "X-Killbill-ApiKey": "appy",
          "X-Killbill-ApiSecret": "appy123",
          "X-Killbill-CreatedBy": "alwasta",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    data.forEach((plan) => {
      const planItem = document.createElement("div");
      planItem.className =
        "card px-2 py-1 rounded-md mt-5 grid xs:grid-cols-2 lg:grid-cols-3 gap-5";

      plan.products
        .filter((e) => e.name === appName)
        .forEach((item) => {
          item.plans.forEach((item) => {
            const parentDiv = document.createElement("div");
            parentDiv.className = `bg-white border px-4 py-5 rounded-md flex flex-col gap-2 items-center justify-center`;
            parentDiv.innerHTML = `
              <h2 class="text-center text-xl font-bold">${item.name}</h2>
              <h5 class="text-center text-sm font-semibold text-gray-400">${appName}</h5>
               <p class="font-bold py-2">${item.phases[0].prices[0].value} ${item.phases[0].prices[0].currency}</p>
        `;

            const button = document.createElement("button");
            button.className =
              "bg-gray-400 px-8 py-2 rounded-xl text-black duration-500 hover:bg-gray-800 hover:text-white disabled:cursor-not-allowed disabled:hover:bg-gray-400";
            button.innerText = "Get started";
            button.addEventListener("click", () => route(item));

            parentDiv.appendChild(button);
            planItem.appendChild(parentDiv);
          });

          // ${item.plans[0].billingPeriod}
        });
      plans.appendChild(planItem);
    });
  } catch (error) {
    console.error("Error fetching catalog data:", error);
  }
}

function route(item) {
  localStorage.setItem(
    "subscription_data",
    JSON.stringify({
      ...item,
      serial_device,
    })
  );

  window.location.href = `../pages/subscription.html${queryString}`;
}

fetchCatalogData();
/*
${
    firstName !== null && `?first_name=${firstName}`
  }&email=${email}&country=${country}&phone_number=${phone}
*/
